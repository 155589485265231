import React, {FC, useEffect, useState} from 'react';
import {
  KratosFlowType,
  KratosFlowValues,
  ResponseErrors,
  useGetKratosFlow,
  useInitialiseKratosFlow
} from '~/hooks/kratos.hooks';
import {styled} from '@mui/material/styles';
import {Button, Paper, TextField} from '@mui/material';
import {KRATOS_URL} from "~/config";
import {GoogleButton} from "~/components/GoogleButton";
import {EMAIL_REGEX} from "~/components/common/email-regex";

export const RecoverPassword: FC<unknown> = () => {

  const [email, setEmail] = useState<string>('');
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
  const [recoverySuccess, setRecoverySuccess] = useState<boolean>(false);

  let flowID: string | undefined;
  let csrfToken: string | undefined;
  let setResponseError: React.Dispatch<React.SetStateAction<ResponseErrors | undefined>>;
  let kratosFlow: KratosFlowValues;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('flow')) {
    kratosFlow = useGetKratosFlow(KratosFlowType.RECOVERY, urlParams.get('flow')!);
  } else {
    kratosFlow = useInitialiseKratosFlow(KratosFlowType.RECOVERY);
  }
  flowID = kratosFlow.flowID;
  csrfToken = kratosFlow.csrfToken;
  setResponseError = kratosFlow.setResponseError;

  const handleEmailSubmit = async () => {
    if (!flowID || !csrfToken) {
      setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
      return;
    } else {
      if (!EMAIL_REGEX.test(email)) {
        setIsInvalidEmail(true);
        return;
      }

      const url = new URL(`${KRATOS_URL}/self-service/recovery`);
      url.searchParams.append('flow', flowID);
      const response = await fetch(url.toString(), {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          csrf_token: csrfToken,
          method: 'link'
        }),
      });

      const responseJson = await response.json();

      if (response.status === 200) {
        setRecoverySuccess(true);
      } else {
        if (responseJson?.ui?.messages.length > 0 && responseJson.ui.messages[0]?.id) {
          const kratosErrorCode = responseJson.ui.messages[0].id as number;
          switch (kratosErrorCode) {
            default:
              setResponseError(ResponseErrors.UNKNOWN_KRATOS_ERROR);
              break;
          }
        } else {
          setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
        }
      }
    }
  };

  // Handles hitting enter button as form submission
  useEffect(() => {
    const page = document.getElementById("recovery-page")
    if (!page) {
      return console.error("login page failed to render")
    }

    const loginSubmitOnEnter = async (event: { key: string; preventDefault: () => void; }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        await handleEmailSubmit();
      }
    };
    page.addEventListener("keypress", loginSubmitOnEnter)
    return () => {
      page.removeEventListener("keypress", loginSubmitOnEnter)
    }
  }, [csrfToken, email])

  const setHelperText = () => {
    if (isInvalidEmail) {
      return 'Invalid email'
    }
    if (recoverySuccess) {
      return 'Sent recovery link, please check your e-mail!'
    }
  }

  return (
    <>
      <LogoDiv>
        <img width={'50%'} height={'auto'} src={'/logo.jpg'}/>
      </LogoDiv>
      <CenteredDiv id={'recovery-page'}>
        <h2>Reset password</h2>
        <AlignedDiv>
          <TextField
            error={isInvalidEmail}
            helperText={setHelperText()}
            id='email'
            name='email'
            label='Email'
            type='email'
            value={email}
            onChange={e => {
              setIsInvalidEmail(false);
              setEmail(e.target.value);
            }
            }
          />
        </AlignedDiv>
        <AlignedDiv>
          <Button
            onClick={handleEmailSubmit}
          >
            Submit
          </Button>
        </AlignedDiv>
      </CenteredDiv>
    </>
  );
};

const errorText = (text: string) => {
  return (
    <ErrorText>{text}</ErrorText>
  );
}

const CenteredDiv = styled(Paper)({
  position: 'fixed',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  display: 'grid',
  justifyContent: 'center',
  gridGap: '10px',
  padding: "10px 40px"
});

const AlignedDiv = styled('div')({
  display: 'block',
  justifySelf: 'center',
  alignItems: 'center',
  marginLeft: 20,
  marginRight: 20,
  '& input': {
    width: 'min(300px, 80vw)',
  },
});

const LogoDiv = styled('div')({
  marginTop: 20,
  marginRight: 20,
  float: 'left',
});

const ErrorText = styled('p')({
  color: 'red',
  fontSize: '0.8em',
  width: 'min(230px, 70vw)',
});
