import React, { FC, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Navigate,
  Route,
} from 'react-router-dom';

import { API_URL, KRATOS_URL } from '~/config';
import { Login } from '~/components/Login';
import { RecoverPassword } from '~/components/RecoverPassword';
import { Settings } from '~/components/Settings';

export const Router: FC<unknown> = () => {

  const isLoggedIn = useIsLoggedIn();
  const {originalUrlPath, originalFlowId} = useGetKratosRedirectState();

  const publicRoutes = (
    <>
      <Route path='login' element={<Login originalUrlPath={originalUrlPath} originalFlowId={originalFlowId} />} />
      <Route path='recover-password' element={<RecoverPassword />} />
      <Route
        path="*"
        element={<Navigate to="login" replace />}
      />
    </>
  );

  const privateRoutes = (
    <>
      <Route path='/' element={<Settings />} />,
      {/*  Add settings page */}
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </>
  );

  // Before page load has loaded
  if (isLoggedIn === undefined) {
    return (
      <></>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? privateRoutes : publicRoutes}
      </Routes>
    </BrowserRouter>

  );
};


const useIsLoggedIn = (): boolean | undefined => {
  const [isLoggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const whoAmI = async () => {
      const authResponse = await fetch(`${KRATOS_URL}/sessions/whoami`, {
        method: 'GET',
        credentials: 'include',
      });
      if (authResponse.status === 200) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    whoAmI().catch(e => console.error(e));
  }, []);

  return isLoggedIn;
};

const useGetKratosRedirectState = (): {originalUrlPath?: string, originalFlowId?: string } => {
  const [originalUrlPath, setOriginalUrlPath] = useState<string | undefined>(undefined);
  const [originalFlowId, setOriginalFlowId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const url = new URL(window.location.href);
    setOriginalUrlPath(url.pathname);
    const flowId = url.searchParams.get('id');
    if (flowId !== null) {
      setOriginalFlowId(flowId);
    }
  }, []);
  return {originalUrlPath, originalFlowId}

}
