import React, {useEffect, useState} from 'react';
import {KRATOS_URL} from '~/config';

export enum ResponseErrors {
  'BAD_CREDENTIALS' = 'Invalid credentials.',
  'PASSWORDS_DONT_MATCH' = 'Passwords do not match.',
  'SESSION_REFRESH_REQUIRED' = 'Session refresh required: please logout then log back in and try again.',
  'PASSWORD_TOO_SHORT' = 'Password must be at least 8 characters long.',
  'PASSWORD_IN_DATA_BREACH' = 'This password has been found in data breaches and must no longer be used. Please provide a diffferent password.',
  'UNKNOWN_KRATOS_ERROR' = 'Service unavailable. Please try again later.',
  'KRATOS_UNAVAILABLE' = 'Service unavailable. Please try again later.'
}

export enum KratosFlowType {
  "LOGIN" = "self-service/login",
  "SETTINGS" = "self-service/settings",
  "RECOVERY" = "self-service/recovery",
}

export interface KratosFlowValues {
  flowID: string | undefined,
  csrfToken: string | undefined,
  returnToUrl: string | undefined,
  googleLinked: boolean | undefined,
  responseError: ResponseErrors | undefined,
  setResponseError: React.Dispatch<React.SetStateAction<ResponseErrors | undefined>>
}

export const useInitialiseKratosFlow = (kratosFlowType: KratosFlowType): KratosFlowValues => {
  const [responseError, setResponseError] = useState<ResponseErrors | undefined>(undefined);
  const [flowID, setFlowID] = useState<string | undefined>(undefined);
  const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined);
  const [returnToUrl, setReturnToUrl] = useState<string | undefined>(undefined);
  const [googleLinked, setGoogleLinked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const requestFlow = async () => {
      let url = new URL(`${KRATOS_URL}/${kratosFlowType}/browser`);
      const flowResponse = await fetch(url.toString(), {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (flowResponse.status !== 200) {
        // TODO: what do we do if kratos is down?
        // browser fire alert for us to debug, get metrics?
        throw new Error(flowResponse.statusText);
      } else {
        const jsonResponse = await flowResponse.json();
        setFlowID(jsonResponse.id);
        setReturnToUrl(jsonResponse.return_to);
        const nodes: { group: string, attributes: {value: string, name: string} }[] = jsonResponse.ui.nodes;

        let csrfToken: string | undefined = undefined;
        nodes.forEach(node => {
          if (node.attributes.name === 'csrf_token') {
            csrfToken = node.attributes.value;
          }
        });
        if (csrfToken) {
          setCsrfToken(csrfToken);
        } else {
          setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
        }

        if (kratosFlowType === KratosFlowType.SETTINGS) {
          nodes.forEach(node => {
            if (node.group === 'oidc') {
              if (node.attributes.value === 'google') {
                if (node.attributes.name === 'link') {
                  setGoogleLinked(false);
                } else {
                  setGoogleLinked(true);
                }
              }
            }
          });
        }
      }
    };

    requestFlow().catch(e => {
      console.error(e);
    });
  }, []);

  return {flowID, csrfToken, returnToUrl, responseError, googleLinked, setResponseError};
};

export const useGetKratosFlow = (kratosFlowType: KratosFlowType, flowID: string): KratosFlowValues => {
  const [responseError, setResponseError] = useState<ResponseErrors | undefined>(undefined);
  const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined);
  const [returnToUrl, setReturnToUrl] = useState<string | undefined>(undefined);
  const [googleLinked, setGoogleLinked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const requestFlow = async () => {
      let url = new URL(`${KRATOS_URL}/${kratosFlowType}/flows`);
      url.searchParams.append("id", flowID);
      const flowResponse = await fetch(url.toString(), {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (flowResponse.status !== 200) {
        // TODO: what do we do if kratos is down?
        // browser fire alert for us to debug, get metrics?
        throw new Error(flowResponse.statusText);
      } else {
        const jsonResponse = await flowResponse.json();
        setReturnToUrl(jsonResponse.return_to);
        const nodes: { group: string, attributes: {value: string, name: string} }[] = jsonResponse.ui.nodes;

        let csrfToken: string | undefined = undefined;
        nodes.forEach(node => {
          if (node.attributes.name === 'csrf_token') {
            csrfToken = node.attributes.value;
          }
        });
        if (csrfToken) {
          setCsrfToken(csrfToken);
        } else {
          setResponseError(ResponseErrors.KRATOS_UNAVAILABLE);
        }
      }
    };

    requestFlow().catch(e => {
      console.error(e);
    });
  }, []);

  return {flowID, csrfToken, returnToUrl, responseError, googleLinked, setResponseError};
}
